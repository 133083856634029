export const getHighlightedChallenges = (resources = {}, highlighted = {}) => {
    // First check the highlighted from the code
    const resourceIds = highlighted?.highlighted?.challenges;
    if (resourceIds && resourceIds.length > 0) {
        return resourceIds.map((id) => resources.byId[id]);
    }

    // Now check the highlighted from the resources
    if (resources.highlighted.challenge) {
        return resources.highlighted.challenge;
    }

    // Finally return the first 2 challenges
    const allChallengeResources = resources.byType.challenge || [];
    return allChallengeResources.slice(0, 2);
};
