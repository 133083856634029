import { useMatches } from 'react-router-dom';
import { validateCode } from '../components/CodeSelector/validateCode';

export const useLanguageAndCodeFromUrl = () => {
    const [urlMatches] = useMatches();
    const params = urlMatches.params || {};

    let language = '';
    let code = '';

    // If nothing is passed
    if (typeof params.language !== 'string') {
        return {
            language,
            code,
        };
    }

    if (
        params.language.length === 5 &&
        validateCode(params.language) === true
    ) {
        code = params.language;
    } else {
        language = params.language;
    }

    if (typeof params.code === 'string') {
        language = params.language;

        if (validateCode(params.code) === true) {
            code = params.code;
        }
    }

    return {
        language,
        code,
    };
};
