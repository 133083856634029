"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.translationsState = exports.languagesState = exports.currentThemeState = exports.currentLanguageState = void 0;
var _recoil = require("recoil");
var _store = _interopRequireDefault(require("store2"));
var _isDarkMode = require("./helpers/isDarkMode");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { "default": e }; }
var translationsState = exports.translationsState = (0, _recoil.atom)({
  key: 'translations',
  "default": {}
});
var currentThemeState = exports.currentThemeState = (0, _recoil.atom)({
  key: 'theme',
  "default": _store["default"].get('theme') || ((0, _isDarkMode.isDarkMode)() ? 'dark' : 'light')
});
var languagesState = exports.languagesState = (0, _recoil.atom)({
  key: 'languages',
  "default": []
});
var currentLanguageState = exports.currentLanguageState = (0, _recoil.atom)({
  key: 'currentLanguage',
  "default": ''
});