import { useTranslations } from 'wtf-components';
import { SectionHeading } from '../../components/SectionHeading';

export const HeroText = () => {
    const { t } = useTranslations();

    return (
        <div className="my-6 md:my-12 mx-auto max-w-4xl">
            <SectionHeading
                level={1}
                className="mb-2"
                align="center"
                text="hero-title"
            />
            <p className="mx-auto max-w-2xl text-sm sm:text-md md:text-xl font-semibold text-center">
                {t('hero-text')}
            </p>
        </div>
    );
};
