import { Alert, useTranslations } from 'wtf-components';
import { Resource } from '../Resource';

export const ResourceList = ({
    title,
    items,
    layout = 'list',
    itemLayout = 'horizontal',
}) => {
    const { t } = useTranslations();

    if (!items || !items.length) {
        return <Alert variant="default">{t('no-resources')}</Alert>;
    }

    return (
        <>
            {title && <h2 className="text-xl font-semibold">{title}</h2>}

            <ul
                className={`
                ${layout === 'grid' ? 'grid sm:grid-cols-2 lg:grid-cols-3 sm:gap-6 items-stretch sm:mt-6' : ''}
            `}
            >
                {items.map((item) => (
                    <li
                        className="mt-6 break-before-column flex items-stretch"
                        key={item.id}
                    >
                        <Resource item={item} layout={itemLayout} />
                    </li>
                ))}
            </ul>
        </>
    );
};
