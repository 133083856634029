import { MediaPlayer, MediaProvider } from '@vidstack/react';
import {
    DefaultVideoLayout,
    defaultLayoutIcons,
} from '@vidstack/react/player/layouts/default';
import { Modal, ModalContainer } from 'wtf-components';

import '@vidstack/react/player/styles/default/theme.css';
import '@vidstack/react/player/styles/default/layouts/video.css';

import './style.scss';

export const ResourceViewer = ({ item, open, onOpenChange }) => {
    return (
        <div className="resource-viewer">
            <ModalContainer open={open} onOpenChange={onOpenChange}>
                <Modal
                    aspectRatio="video"
                    className="p-0 w-full sm:w-2/3 overflow-hidden"
                    setWidth={false}
                >
                    {item.linkType === 'embedded' && (
                        <div
                            className="embedded"
                            dangerouslySetInnerHTML={{ __html: item.link }}
                        />
                    )}
                    {item.linkType === 'youtube' && (
                        <MediaPlayer src={`youtube/${item.link}`} autoPlay>
                            <MediaProvider />
                            <DefaultVideoLayout icons={defaultLayoutIcons} />
                        </MediaPlayer>
                    )}
                    {item.linkType === 'vimeo' && (
                        <MediaPlayer src={`vimeo/${item.link}`} autoPlay>
                            <MediaProvider />
                            <DefaultVideoLayout icons={defaultLayoutIcons} />
                        </MediaPlayer>
                    )}
                </Modal>
            </ModalContainer>
        </div>
    );
};
