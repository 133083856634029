"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getRequestObject = void 0;
var _getUrl = require("./api/getUrl");
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
/**
 * Form the request object to be sent to the fetch method
 *
 * @param {Object} requestConfig
 *
 * @return {Request}
 */
var getRequestObject = exports.getRequestObject = function getRequestObject(requestConfig) {
  // Form the body (if any)
  var body;
  if (requestConfig.body) {
    body = JSON.stringify(requestConfig.body);
  }

  // Form the method
  var method = requestConfig.method || 'GET';

  // Form the url
  var url = (0, _getUrl.getUrl)(requestConfig.url, requestConfig.api || 'public');
  if (_typeof(requestConfig.params) === 'object') {
    var urlParams = new URLSearchParams();
    for (var param in requestConfig.params) {
      urlParams.set(param, requestConfig.params[param]);
    }
    url += '?' + urlParams.toString();
  }
  return new Request(url, {
    method: method,
    body: body
  });
};