export const getHighlightedMovies = (resources = {}, highlighted = {}) => {
    // First check the highlighted from the code
    const resourceIds = highlighted?.highlighted?.movies;
    if (resourceIds && resourceIds.length > 0) {
        return resourceIds.map((id) => resources.byId[id]);
    }

    // Now check the highlighted from the resources
    if (resources.highlighted.movie) {
        return resources.highlighted.movie;
    }

    // Finally return the first 3 documentaries
    const allChallengeResources = resources.byType.documentary || [];
    return allChallengeResources.slice(0, 3);
};
