import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';

export const useFormSchema = () => {
    const formSchema = z.object({
        petitionType: z.string().min(1, {
            message: 'Peitition type is required',
        }),
        name: z.string().min(1, { message: 'Name is required' }),
        email: z.string().email({ message: 'Invalid email address' }),
        noUpdates: z.boolean(),
    });

    return useForm({
        resolver: zodResolver(formSchema),
        defaultValues: {
            name: '',
            email: '',
        },
    });
};
