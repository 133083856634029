import { useRecoilValue } from 'recoil';
import { TfiVideoClapper, TfiVideoCamera, TfiViewGrid } from 'react-icons/tfi';
import { GiKnifeFork, GiBookCover } from 'react-icons/gi';
import { TbWorldWww } from 'react-icons/tb';
import { BigTabs, BigTab, useTranslations } from 'wtf-components';
import { ResourcesTab } from './ResourcesTab';
import { resourcesState } from '../../recoilState';
import { SectionHeading } from '../../components/SectionHeading';

export const Resources = () => {
    const resources = useRecoilValue(resourcesState);
    const iconSize = '2rem';
    const { t } = useTranslations();

    return (
        <div className="resources">
            <SectionHeading className="mb-4 sm:mb-6" text="resources-heading" />

            <BigTabs>
                <BigTab
                    name={t('resources-tab-videos')}
                    icon={<TfiVideoClapper size={iconSize} />}
                >
                    <ResourcesTab items={resources.byType.video} />
                </BigTab>
                <BigTab
                    name={t('resources-tab-documentaries')}
                    icon={<TfiVideoCamera size={iconSize} />}
                >
                    <ResourcesTab items={resources.byType.documentary} />
                </BigTab>
                <BigTab
                    name={t('resources-tab-challenges')}
                    icon={<GiKnifeFork size={iconSize} />}
                >
                    <ResourcesTab items={resources.byType.challenge} />
                </BigTab>
                <BigTab
                    name={t('resources-tab-websites')}
                    icon={<TbWorldWww size={iconSize} />}
                >
                    <ResourcesTab items={resources.byType.website} />
                </BigTab>
                <BigTab
                    name={t('resources-tab-apps')}
                    icon={<TfiViewGrid size={iconSize} />}
                >
                    <ResourcesTab items={resources.byType.app} />
                </BigTab>
                <BigTab
                    name={t('resources-tab-books')}
                    icon={<GiBookCover size={iconSize} />}
                >
                    <ResourcesTab
                        items={resources.byType.book}
                        itemLayout="horizontal"
                    />
                </BigTab>
            </BigTabs>
        </div>
    );
};
