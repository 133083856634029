import { Button, useTranslations } from 'wtf-components';
import {
    BsEmojiFrown,
    BsEmojiExpressionless,
    BsEmojiSmile,
    BsEmojiHeartEyes,
} from 'react-icons/bs';
import './style.scss';

export const FeedbackForm = ({ className }) => {
    const { t } = useTranslations();
    const iconSize = '25px';
    const answers = [
        {
            icon: <BsEmojiFrown size={iconSize} />,
            text: t('feedback-nothing'),
            value: 'nothing',
        },
        {
            icon: <BsEmojiExpressionless size={iconSize} />,
            text: t('feedback-research'),
            value: 'research',
        },
        {
            icon: <BsEmojiSmile size={iconSize} />,
            text: t('feedback-reduce'),
            value: 'reduce',
        },
        {
            icon: <BsEmojiHeartEyes size={iconSize} />,
            text: t('feedback-vegan'),
            value: 'vegan',
        },
    ];
    return (
        <article className={className}>
            <h2 className="text-xl font-semibold">{t('feedback-question')}</h2>

            <div className="grid sm:grid-cols-4 gap-4 mt-4">
                {answers.map(({ icon, text, value }) => (
                    <Button
                        variant="outline"
                        className="py-4 whitespace-normal flex flex-row items-center sm:block"
                        size="auto"
                        key={value}
                    >
                        <div className="emoji-container text-center me-3 sm:me-0 sm:mb-3">
                            {icon}
                        </div>
                        <div className="text-center">{text}</div>
                    </Button>
                ))}
            </div>
        </article>
    );
};
