import { range } from 'lodash';
import {
    Button,
    Modal,
    useTranslations,
    ModalContainer,
    ModalTrigger,
    Form,
    FormFieldSelect,
    FormFieldTextInput,
    FormFieldCheckbox,
} from 'wtf-components';
import './style.scss';
import { SectionHeadingText } from '../../components/SectionHeading';

import { useFormSchema } from './useFormSchema';

export const OneMillionForAnimals = () => {
    const { t } = useTranslations();

    const optionNumbers = range(5, 0);

    // Handle form submit
    const form = useFormSchema();
    function onSubmit(values) {
        // Do something with the form values.
        // ✅ This will be type-safe and validated.
        console.log(values);
    }

    return (
        <>
            <ModalContainer>
                <article className="one-million-for-animals px-6 sm:px-10 md:px-16 py-8 sm:py-16 md:py-24 rounded-lg text-white">
                    <div className="relative z-20 sm:w-1/2 md:w-1/3">
                        <h2 className="text-xl sm:text-2xl md:text-3xl text-start font-semibold tracking-tight text-white uppercase mb-4">
                            <SectionHeadingText
                                text="one-million-heading"
                                boldClassName="block"
                            />
                        </h2>

                        <p className="text-white mb-6 text-sm md:text-md sm:text-xl w-3/5 sm:w-full">
                            {t('one-million-description')}
                        </p>

                        <ModalTrigger>
                            <Button variant="wtf-pink" size="wide">
                                {t('one-million-sign-petition')}
                            </Button>
                        </ModalTrigger>
                    </div>
                </article>
                <Modal
                    className="p-0"
                    title={
                        <div className="text-2xl text-start font-semibold tracking-tight uppercase flex flex-row items-center p-5 pb-0">
                            <img
                                src="images/chick.png"
                                alt=""
                                className="h-16 me-3"
                            />
                            <span>
                                <SectionHeadingText text="one-million-heading" />
                            </span>
                        </div>
                    }
                >
                    <div className="p-6 pt-0 space-y-4">
                        <h4 className="text-xl font-bold">
                            {t('one-million-policy-title')}
                        </h4>

                        <div className="whitespace-pre-line">
                            {t('one-million-policy-description')}
                        </div>
                    </div>

                    <Form {...form}>
                        <form
                            className="p-6 bg-gray-200 dark:bg-gray-900 rounded-b-md mt-4"
                            onSubmit={form.handleSubmit(onSubmit)}
                        >
                            <FormFieldSelect
                                form={form}
                                name="petitionType"
                                label={t('one-million-i-will')}
                                options={optionNumbers.map((count) => ({
                                    value: count.toString(),
                                    label: t(
                                        `one-million-i-will-option-${count}`
                                    ),
                                }))}
                                placeholder={t('one-million-i-will')}
                            />

                            <div className="flex flex-row items-end mt-4 gap-4">
                                <FormFieldTextInput
                                    form={form}
                                    name="name"
                                    className="grow base-0"
                                    label={t('one-million-form-signed')}
                                    placeholder={t('one-million-form-name')}
                                />
                                <FormFieldTextInput
                                    form={form}
                                    name="email"
                                    type="email"
                                    className="grow base-0"
                                    label={t('one-million-form-email')}
                                    placeholder={t('one-million-form-email')}
                                />
                            </div>

                            <FormFieldCheckbox
                                form={form}
                                name="noUpdates"
                                className="mt-4 flex flex-row items-center space-x-3 space-y-0"
                                label={t('one-million-form-no-updates')}
                            />

                            <div className="text-center mt-4">
                                {/* Button added to make the submit work */}
                                <Button type="submit">
                                    {t('one-million-form-submit')}
                                </Button>
                                <div className="mt-2">
                                    <a
                                        className="inline-block decoration-1 hover:text-wtf-pink"
                                        href="https://www.activism.wtf/privacy"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {t('one-million-data-privacy-policy')}
                                    </a>
                                </div>
                            </div>
                        </form>
                    </Form>
                </Modal>
            </ModalContainer>
        </>
    );
};
