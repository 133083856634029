import { isArray } from 'lodash';
import { useSetRecoilState } from 'recoil';
import { Preloader, useTranslations } from 'wtf-components';
import { useLanguageAndCodeFromUrl } from '../../hooks/useLanguageAndCodeFromUrl';
import {
    currentCodeState,
    resourcesState,
    highlightedState,
} from '../../recoilState';
import { getLanguage } from './getLanguage';
import { appConfig } from '../../config/app';
import { parseResources } from './parseResources';
import { parseHighlighted } from './parseHighlighted';
import { useChangeCode } from '../../hooks/useChangeCode';
import { useChangeLanguage } from '../../hooks/useChangeLanguage';

import _mockTranslations from './_mock_translations.json';

export const MainPreloader = ({ children }) => {
    // Get the language and the code from the URL
    // TODO: Make sure this isn't the single source of truth
    let { language, code } = useLanguageAndCodeFromUrl();
    const changeCode = useChangeCode();
    const changeLanguage = useChangeLanguage();

    const setCurrentCode = useSetRecoilState(currentCodeState);

    // const currentCode = useRecoilValue(currentCodeState);

    const setResources = useSetRecoilState(resourcesState);
    const setHighlighted = useSetRecoilState(highlightedState);

    const {
        setTranslations,
        setLanguages,
        currentLanguage,
        setCurrentLanguage,
    } = useTranslations();

    // These requests don't depend on anything - usually happen at first
    const generalRequests = [
        {
            url: 'languages',
            validateResponse: ({ data }) => {
                return isArray(data.data) && data.data.length > 0;
            },
            callback: ({ data }) => {
                // Set the languages
                const languages = data.data.reduce(
                    (obj, item) => ({
                        ...obj,
                        [item.code]: item,
                    }),
                    {}
                );
                setLanguages(languages);

                // Validate and set the current language
                const newLanguage = getLanguage(language, languages);
                setCurrentLanguage(newLanguage);

                // Change the url
                if (newLanguage.code !== language) {
                    changeLanguage(newLanguage.code);
                }
            },
        },
        {
            url: 'validateCode',
            validateResponse: ({ data }) => isArray(data.data),
            params: {
                'filter[code]': code || '-',
            },
            callback: ({ data }) => {
                // TODO: What do we do if the code is not active?
                // data.data[0].is_active

                // Set the current code, since it's clearly valid
                const firstCode = data.data.find(Boolean);
                setCurrentCode(firstCode || appConfig.defaultCode);

                // Change the url
                const newCode = firstCode?.code || '';
                if (newCode !== code) {
                    changeCode(newCode, {
                        replace: true,
                    });
                }
            },
        },
    ];

    // The thirds requests are only fetched if there is a language and a code
    const languageDependentRequests = [
        {
            url: 'resources',
            callback: ({ data }) => {
                const resources = parseResources(data.data);
                setResources(resources);
            },
            language: currentLanguage.code,
        },
        {
            url: 'translations',
            validateResponse: ({ data }) => Boolean(data.data),
            callback: ({ data }) => {
                const translations = data.data.reduce(
                    (obj, rawItem) => ({
                        ...obj,
                        [rawItem.key]: rawItem.value,
                    }),
                    {}
                );

                // Set translations
                setTranslations({
                    ..._mockTranslations,
                    ...translations,
                });
            },
            language: currentLanguage.code,
            params: {
                'filter[platform]': '3movies',
            },
        },
    ];

    const codeDependentRequests = [
        {
            url: 'highlighted',
            callback: ({ data }) => {
                const parsedData = parseHighlighted(data.data);
                setHighlighted(parsedData);
            },
            params: {
                'filter[code]': code || '',
            },
        },
    ];

    return (
        <Preloader requests={generalRequests}>
            <Preloader
                requests={languageDependentRequests}
                refetch={[currentLanguage]}
            >
                <Preloader requests={codeDependentRequests} refetch={[code]}>
                    {children}
                </Preloader>
            </Preloader>
        </Preloader>
    );
};
