import { useState } from 'react';
import { Button, useTranslations } from 'wtf-components';
import { BiSolidLeftArrow, BiSolidRightArrow } from 'react-icons/bi';
import { ResourceList } from '../../components/ResourceList';

export const ResourcesTab = ({ items, itemLayout = 'vertical' }) => {
    const { t, currentLanguage } = useTranslations();

    // Paginate
    const perPage = 6;
    const [page, setPage] = useState(1);
    const limitStart = 0;
    const limitEnd = page * perPage;
    const paginatedItems = items.slice(limitStart, limitEnd);

    return (
        <>
            {items && items.length > 0 && (
                <div className="flex flex-row items-top">
                    <div className="me-2 pt-1 text-wtf-pink text-md sm:text-xl md:text-3xl lg:text-4xl">
                        {currentLanguage.direction === 'ltr' ? (
                            <BiSolidRightArrow />
                        ) : (
                            <BiSolidLeftArrow />
                        )}
                    </div>
                    <div className="text-white text-sm sm:text-md dark:text-gray-800">
                        {t('resources-text')}
                    </div>
                </div>
            )}

            <ResourceList
                layout={itemLayout === 'horizontal' ? 'list' : 'grid'}
                itemLayout={itemLayout}
                items={paginatedItems}
            />

            {items.length > limitEnd && (
                <div className="flex flex-col items-center mt-4">
                    <div className="w-48">
                        <Button
                            variant="secondary"
                            onClick={() => setPage(page + 1)}
                        >
                            {t('load-more-resources')}
                        </Button>
                    </div>
                </div>
            )}
        </>
    );
};
