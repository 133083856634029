import { useRecoilValue } from 'recoil';
import { Resource } from '../../components/Resource';
import { getHighlightedChallenges } from './getHighlightedChallenges';
import { resourcesState } from '../../recoilState';
import { SectionHeading } from '../../components/SectionHeading';

export const Challenges = () => {
    const resources = useRecoilValue(resourcesState);
    const challenges = getHighlightedChallenges(resources);

    return (
        <div className="challenges mt-8 md:mt-16 lg:mt-24">
            <SectionHeading text="support-heading" />
            <ul className="grid sm:mt-4 md:mt-6 sm:grid-cols-2 sm:gap-6 md:gap-10 lg:gap-14 items-stretch">
                {challenges.map((item) => {
                    return (
                        <li
                            className="mt-6 sm:mt-0 break-before-column flex items-stretch"
                            key={item.id}
                        >
                            <Resource
                                item={item}
                                layout="vertical"
                                highlighted
                            />
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};
